import React from 'react'
import Layout from '../../layouts/Layout'
import BasicHero from '../../components/Hero/BasicHero'

import { Container, Flex } from 'theme-ui'

export default function news() {
  return (
    <Layout theme={'dark'}>
      <BasicHero heading="News" />
      <Container
        sx={{
          flexDirection: 'column',
          p: {},
        }}
      >
        <h2 sx={{ mb: 0, textAlign: 'left' }}>
          Attain Finance converts First Heritage Credit branches to Heights
          Finance
        </h2>{' '}
        <em sx={{ mb: 3, mt: 3 }}>
          - Rebranding effort includes all 102 locations across south and
          southeastern U.S. -
        </em>
        <p>Greenville, South Carolina | March 17, 2025</p>
        <p>
          In an ongoing effort to streamline operations and better serve its
          customers, Attain Finance has announced all First Heritage branches
          will begin the process to convert to the company’s U.S. flagship
          consumer-facing brand, Heights Finance.
        </p>{' '}
        <p>
          This change allows the company to offer expanded loan options, as well
          as more modern and streamlined payment options not previously
          available to First Heritage customers.
        </p>{' '}
        <p>
          <i>
            “We believe this change offers the best of both worlds for our
            customers. They’ll have more financial options and a better overall
            experience than ever before, while also being able to visit the same
            branch location and work with the same team members that they’ve
            come to know and trust,”
          </i>{' '}
          said Doug Clark, Attain Finance CEO.
        </p>{' '}
        <p>
          Heights Finance will operate over 350 branches and more than 1,500
          employees across 13 states. The company specializes in secured and
          unsecured loans up to $10,000 or more and has helped more than 550,000
          consumers get the money they need, when they need it.
        </p>{' '}
        <p>
          For more information on Heights Finance, please visit
          HeightsFinance.com.
        </p>{' '}
        <i sx={{ fontWeight: 300 }}>
          Attain Finance is a leading consumer credit lender serving the U.S.
          and Canada for over 50 years. We’ve worked diligently to provide our
          customers with a variety of convenient and easily accessible financial
          services, and our decades of diversified data powers a
          hard-to-replicate underwriting and scoring engine that mitigates risk
          across the full spectrum of credit products. Attain Finance operates a
          number of consumer-facing brands including Heights Finance in the U.S.
          and Cash Money and Lend Direct in Canada. For more information on
          Attain Finance, please visit AttainFinance.com.
        </i>{' '}
        <p>
          For more information about Attain Finance, please visit{' '}
          <a href="https://www.attainfinance.com" title="Attain Finance">
            www.attainfinance.com
          </a>
          .
        </p>
        <br />
        Contact Information:
        <br />
        <br />
        <div
          sx={{
            div: {
              mb: 3,
            },
          }}
        >
          <Flex>
            <span sx={{ width: 100 }}>Company:</span>{' '}
            <span>Attain Finance</span>
          </Flex>
          <Flex>
            <span sx={{ width: 100 }}>Email:</span>{' '}
            <a href="mailto:IR@attainfinance.com" target="_blank">
              IR@attainfinance.com{' '}
            </a>
          </Flex>
          <Flex>
            <span sx={{ width: 100 }}>Website:</span>{' '}
            <div>
              <a href="https://www.attainfinance.com" target="_blank">
                AttainFinance.com
              </a>
              <br />
              <br />
              <a href="https://www.heightsfinance.com/" target="_blank">
                HeightsFinance.com
              </a>
            </div>
          </Flex>
          <Flex
            sx={{
              flexWrap: 'wrap',
            }}
          >
            <span sx={{ width: 100, mb: [3, null, null, 0] }}>LinkedIn:</span>
            <Flex
              sx={{
                flexWrap: 'wrap',
              }}
            >
              <a
                href="https://www.linkedin.com/company/attain-finance/"
                target="_blank"
              >
                https://www.linkedin.com/company/attain-finance/
              </a>
              <br />
              <br sx={{ display: ['none', null, null, 'block'] }} />
              <a
                href="https://www.linkedin.com/company/heights-finance-corporation/"
                target="_blank"
              >
                https://www.linkedin.com/company/heights-finance-corporation/
              </a>
            </Flex>
          </Flex>
        </div>
        <br />
      </Container>{' '}
    </Layout>
  )
}

// export const Head = ({ data }) => {
//   const page = data.contentfulPageJson
//   return <Seo title={page.pageTitle} />
// }
